// Previously: blog.tsx

import React from 'react';
import { graphql } from 'gatsby';
import { mapEdgesToNodes } from '../lib/helpers';
import BlogPostPreviewGrid from '../components/blog/blog-post-preview-grid/blog-post-preview-grid';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/blog/seo';
import Layout from '../containers/layout';

import ContactSection from '../components/layout/ContactSection/contactSession';

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query BlogPageQuery {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const BlogPage = (props: any) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts);

  return (
    <Layout>
      <SEO title='Blog' />
      {postNodes && postNodes.length > 0 && <BlogPostPreviewGrid nodes={postNodes} />}
      <ContactSection link='/assets/images/multi_blog_contact.png' />
    </Layout>
  );
};

export default BlogPage;
